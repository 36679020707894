<template>
  <div>
    <!-- Start of breadcurmb section
        ============================================= -->
    <section id="saasio-breadcurmb" class="saasio-breadcurmb-section">
      <div class="container">
        <div class="breadcurmb-title text-center">
          <h2>News Feed</h2>
        </div>
        <div class="breadcurmb-item-list text-center ul-li">
          <ul class="saasio-page-breadcurmb">
            <li><router-link to="/">ACCUEIL</router-link></li>
            <li><a href="javascript:void(0)"> {{ blogTitle }}</a></li>
          </ul>
        </div>
      </div>
    </section>
    <!-- End of breadcurmb section
        ============================================= -->

    <!-- Start of blog page section
        ============================================= -->
    <section id="news-feed" class="news-feed-section">
      <div class="container">
        <div class="blog-feed-content">
          <div class="row">
            <div class="col-md-8">
              <div class="saasio-blog-details-content">
                <div class="blog-details-img">
                  <img src="assets/images/blogs/crm9.PNG" alt="C'est quoi un logiciel CRM" />
                </div>
                <div class="blog-details-text dia-headline">
                  <h1>{{ blogTitle }}</h1>
                  <article>
                    Un logiciel CRM (Customer Relationship Management) est un outil informatique utilisé pour gérer les
                     interactions avec les clients et les prospects d'une entreprise. Les logiciels CRM sont conçus pour
                      aider les entreprises à améliorer leur relation client en leur permettant de suivre les interactions
                       avec les clients, de gérer les données client, 
                    de suivre les ventes et les opportunités commerciales, de fournir un support client efficace, etc.
                  </article>
                  <article>
                    Un logiciel CRM peut inclure des fonctionnalités telles que la gestion de contacts, la gestion de leads, 
                    la gestion de campagnes de marketing, la gestion de la force de vente, la gestion de projets, la gestion 
                    du service clientèle, la gestion des plaintes et des réclamations, la gestion des tickets d'assistance, etc.
                     Les données stockées dans un logiciel CRM peuvent être utilisées pour analyser les tendances de vente,
                      identifier les opportunités commerciales, personnaliser les communications avec les clients et améliorer 
                      la stratégie marketing de l'entreprise.
                  </article>
                </div>

                <ShareContent />
                
              </div>
            </div>
          
            <RightSideBar />

          </div>
        </div>
      </div>
    </section>
    <!-- End of blog section
        ============================================= -->
  </div>
</template>

<script>
import ShareContent from '../components/ShareContent.vue';
import RightSideBar from '../components/RightSideBar.vue';
export default {
    name : 'BlogDetail2',
    components : {
      ShareContent,
      RightSideBar
    },
    data() {
      return {
        //blogDetail : require('../assets/blogs/crm9.PNG'),
        blogTitle : "C'est quoi un logiciel CRM ?"
      }
    }
};
</script>

<style>
</style>